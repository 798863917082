<template lang="pug">
  v-container(fluid="" tag="section" style='padding:0px;')
    v-card
      v-card-title.headline
        | Ingresa tus datos de inicio de sesión
      v-divider
      v-card-text
        v-form(ref='form' v-model='reauthFormValid' :lazy-validation='lazy')
          v-row
            v-col(cols='12')
              v-text-field(v-model='reauthenticCredent.email' type='text' label='Correo de usuario *' required='' :rules="[v => !!v || 'El correo es requerido']")
            v-col(cols='12')
              v-text-field(v-model='reauthenticCredent.password' :append-icon="showPassLogin ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassLogin = !showPassLogin" :type="showPassLogin ? 'text' : 'password'" label='Contraseña *' required='' :rules="[v => !!v || 'La contraseña es requerida']")
              small Los campos marcados con * son obligatorios
      v-divider
      v-card-actions
        v-spacer
          v-btn(color='red accent-1' text='' @click='cancelReauthenticDialog')
            | CANCELAR
          v-btn.mr-4(color='blue darken-1' :disabled='!reauthFormValid' @click='evaluateCredentials(reauthenticCredent.email, reauthenticCredent.password)')
            | LISTO
</template>
<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  export default {
    components: {
    },
    data: () => ({
      showPassLogin: false,
      reauthenticCredent: {
        email: '',
        password: '',
      },
      showReauthenticDialog: null,
      correctCredentials: false,
    }),
    methods: {
      cancelReauthenticDialog () {
        this.showReauthenticDialog = false
        this.$emit('closeReauthenticDialog', this.showReauthenticDialog)
      },
      async evaluateCredentials (email, password) {
        const user = firebase.auth().currentUser
        const credential = firebase.auth.EmailAuthProvider.credential(email, password)
        await user.reauthenticateWithCredential(credential).then((result) => {
          this.correctCredentials = true
        }).catch(() => {
          this.correctCredentials = false
        })
        this.$emit('reauthenticResolve', this.correctCredentials)
        this.reauthenticCredent = {
          email: '',
          password: '',
        }
      },
    },
  }
</script>
<style scoped>
</style>
